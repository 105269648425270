import React from 'react';
import CommonHeader from '../CommonHeader';
import AboutDescription from './AboutDescription';
import CountSection from './CountSection';
import AboutSubSection from './AboutSubSection';
import AboutCardSection from './AboutCardSection';

const About = () => {
  return (
    <div >
   <CommonHeader title={"ABOUT US"} data={"About"}/>
   <AboutDescription />
   <CountSection/>  
   <AboutSubSection/> 
   <AboutCardSection/> 
</div>
  )
}

export default About