import CountUp from 'react-countup';
const CountSection = () => {
  return (
    <div className='container d-flex justify-content-around'>
        <div>
      <span><CountUp style={{fontSize:"35px"}} end={16} duration={14}/><sup style={{fontSize:"22px",color: "#ff6635"}}>K+</sup></span>
      <h3>App Downloads</h3>
      
    </div> 
    <div>
      <span ><CountUp style={{fontSize:"35px"}} end={12} duration={14}/><sup style={{fontSize:"22px",color: "#ff6635"}}>K+</sup></span>
      <h3>Active Users</h3>
      
    </div> 
    <div>
      <span><CountUp style={{fontSize:"35px"}} end={95} duration={10}/><sup style={{fontSize:"22px",color: "#ff6635"}}>%</sup></span>
      <h3>Happy Users</h3>
      
    </div> 
    <div>
      <span><CountUp style={{fontSize:"35px"}} end={4.6} duration={18}/><sup style={{fontSize:"22px",color: "#ff6635"}}>%</sup></span>
      <h3>App Rating</h3>      
    </div> 
    </div>
  )
}

export default CountSection;