import React from 'react';
import HeroSection from './HeroSection';
import AboutSection from './AboutSection';
import CardSection from './CardSection';
import FeaturesSection from './FeaturesSection';

const Home = () => {
  return (
    <div >
   
    <HeroSection />
    <AboutSection />
    <CardSection />
    <FeaturesSection />
    
   </div>
  )
}

export default Home