
const AboutCardSection = () => {
  return (
    <div style={{paddingBottom:"5%"}}>
  <div className="container d-flex">
        <div style={{paddingRight:"5%",width:"30%"}}>
            <h3 style={{fontSize: "21px",color: "black",textAlign:"center",lineHeight:"1.5em",letterSpacing: "0.5px",fontWeight:"600"}}>01.</h3>
            <p style={{fontSize:"14px",color:"#7A7A7A",textAlign:"center"}}>Online with real player, play anytime anywhere.</p>
        </div>
        <div style={{paddingRight:"5%",width:"60%"}}>
            <h3 style={{fontSize: "21px",color: "black",textAlign:"center",lineHeight:"1.5em",letterSpacing: "0.5px",fontWeight:"600"}}>02.</h3>
            <p style={{fontSize:"14px",color:"#7A7A7A",textAlign:"center"}}>Fair Play Policy, We have a fair play policy that controls any type of fraud and collision, giving each player a fair opportunity to win the game.</p>
        </div>
        <div style={{paddingRight:"5%",width:"40%"}}>
            <h3 style={{fontSize: "21px",color: "black",textAlign:"center",lineHeight:"1.5em",letterSpacing: "0.5px",fontWeight:"600"}}>03.</h3>
            <p style={{fontSize:"14px",color:"#7A7A7A",textAlign:"center"}}>Professional development team provide 100% secure App, 24 * 7 service support.</p>
        </div>
    </div>
    <div className="container d-flex">
        <div style={{paddingRight:"5%",width:"40%"}}>
            <h3 style={{fontSize: "21px",color: "black",textAlign:"center",lineHeight:"1.5em",letterSpacing: "0.5px",fontWeight:"600"}}>04.</h3>
            <p style={{fontSize:"14px",color:"#7A7A7A"}}>Multi game modes, 2 players, 5 players, and multi level of basic score in each game room.</p>
        </div>
        <div style={{paddingRight:"5%",width:"30%"}}>
            <h3 style={{fontSize: "21px",color: "black",textAlign:"center",lineHeight:"1.5em",letterSpacing: "0.5px",fontWeight:"600"}}>05.</h3>
            <p style={{fontSize:"14px",color:"#7A7A7A",textAlign:"center"}}>Plenty of bonus from aperiodic activities.</p>
        </div>
        <div style={{paddingRight:"5%",width:"75%"}}>
            <h3 style={{fontSize: "21px",color: "black",textAlign:"center",lineHeight:"1.5em",letterSpacing: "0.5px",fontWeight:"600"}}>06.</h3>
            <p style={{fontSize:"14px",color:"#7A7A7A",textAlign:"center"}}>Quick payment, instant withdrawal with Secure Payment Gateways, We offer multiple payment options right from debit cards, UPI, eWallets and Netbanking. There is also instant withdraw options where players can see their winning amount in the bank account right away.</p>
        </div>
    </div>
   
    </div>
  )
}

export default AboutCardSection