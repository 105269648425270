import React from 'react'
import CommonHeader from '../CommonHeader';
import DetailsSection from './DetailsSection';

const Contact = () => {
  return (
    <div>
      <CommonHeader title={"CONTACT US"}data={"Our Contact"} />
      <DetailsSection/>
      
    </div>
  )
}

export default Contact