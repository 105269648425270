import CommonHeader from "../CommonHeader"

const TermsSection = () => {
  return (
    <div>
        <CommonHeader title={"Terms Conditions"} data = {"TERMS CONDITIONS"}/>
        <div className="container d-flex flex-column privacy-section">
            <p>Products and/or services relating to the games (hereinafter collectively referred to as “Services”) provided to you and offered by DS INDIA CORPORATION and/or its affiliates, subsidiaries, associates and partners (hereinafter referred to as “We” or “Us” or “Our” Or “Company”) through its mobile application, namely, (hereinafter referred to as the “Application”) are subject to the Terms of Use and Service (hereinafter referred to as “Terms”) as specified herein.</p>
            <p>These Terms, as updated or modified from time to time, constitute an agreement between you and Company and shall govern the relationship between you and Company with respect to Services. You must read, understand and agree to, and be bound by, the Terms for accessing the Services. You must not access the Application or Services, in any manner, in case you do not expressly accept these Terms in its entirety.</p>
            <h4>1. AGREEMENT</h4>
            <p>1.1. These Terms along with privacy policy (hereinafter “Privacy Policy”) and the rules of the game-play, that is, Game Rules (as defined hereinafter) as may be notified and updated from time to time by the Company, constitutes a binding agreement between you and Company. You must read, understand and accept all the provisions envisaged under these Terms, Privacy Policy and Game Rule before accessing the Services and/or the Application in any manner. You understand and agree that Company reserves the right to modify or update these Terms, Privacy Policy and Game Rules at any time without needing to serve a prior notice upon you. Any such modification or update in the Terms, Privacy Policy and Game Rules shall, however, be intimated to you under the ‘Notice’ tab within the Application. You must accept the Terms, Privacy Policy and Game Rules so modified or updated to continue accessing the Services and Application. In case you do not accept the Terms, Privacy Policy and Game Rules so modified or updated, you must cease to access the Services and Application forthwith, and shall cancel your membership (as referred to hereinafter) and inform the Company immediately. If case you continue to access Services and Application after any such modification or update to these Terms, Privacy Policy and/or Game Rules, it shall be assumed that you have agreed to such modification or update, and be bound by the same.</p>
            <h4>2. MEMBERSHIP</h4>
            <p>2.1. Any information provided by you to us, whether at the stage of registration or during any time subsequently, should be complete and truthful.</p>
            <p>2.2. Your application towards registration as a member and a registered user of the Services shall be deemed as you having expressly agreed to these Terms, Privacy policy and Game Rules, as updated from time to time, and therefore, shall be bound by the same at all times. Your registration as a member and registered user of Services shall be subject to discretion of the Company, and the Company shall reserve the right to terminate your registration by serving an instant notice based on subjective judgement and reasons.</p>
            <p>2.3. Without prejudice to the foregoing, the membership and access to Services shall be allowed to any person above the age of 18 years, and the registration for membership shall be guided by the registration process stated in the Application. The Company reserves the right to verify the age of you as a user of Services by requesting for specified documentary proof in this behalf, and shall also reserve the right to impose ban in the event any documentary proof is found to be false.</p>
            <p>2.4. Notwithstanding, any person requesting registration/membership shall, at all times, be bound by the applicable laws in the jurisdiction in which such person is situated. The Company shall be not be liable, in any manner, towards violation of any such applicable laws by the person requesting registration/membership. Neither Company, nor any other third-party providing any content on the Application, nor their related agents shall be responsible and liable for any direct or indirect, incidental or consequential injuries or damages that can arise as a result of the use of, or inability to use, Our Application by any person who is below the age of 18 years.</p>
            <p>2.5. You acknowledge and agree that We are not obligated to cross-check or verify any of your information and hence, We do not take any responsibility for any consequences or outcomes that may result from your providing false, incorrect, incomplete, concealed or irrelevant information to Us. Therefore, you are required to provide Us with complete and authentic information to the best of your knowledge to play Our Games. Also, you should not be a politically exposed person or convicted of any crimes. You should not be on the UN sanction list/ work for UN sanction list.</p>
            <p>2.6. You represent that you are not a politically exposed person.</p>
            <p>2.7 You represent that you have not been convicted of any crime.</p>
            <p>2.8 You represent that you are not on the UN Sanctions List or work for a company that is on the UN Sanctions list.</p>
            <h4>3. GAME VARIATIONS</h4>
            <p>3.1. The Company may offer various promotional games, practice games, cash games and other non-cash games on its Applications (hereinafter collectively referred as “Games”). Each of such Games shall be governed by specified set of rules of game-play notified by the Company (hereinafter referred to as “Game Rules”). The Game Rules can be found in the ‘Help’ section in the Application. You are required to play any of such Games solely in accordance with such notified Game Rules. 3.2. The Company offers the Games in two categories:<br/>I. Cash Game(s) are such Games that require participants to contribute make certain payments and/or to maintain a certain minimum cash balance in their account to participate. II. Non-cash Game(s) are such Games that does not require participants to contribute or make any payments or to maintain a certain minimum cash balance in their account to participate, that is to say, such Games are completely free to be played.</p>
            <h4>4. ACCOUNT CREATION AND OPERATIONS</h4>
            <p>4.1. You must register Yourself through the Application before accessing Services or playing the Game(s). The registration can be done by clicking on the “Register” section in the Application, and the registration can be proceeded with in accordance with the steps directed therein. In order to register Yourself, You must create your unique login name and password after submission of certain information as may be required therein. Further, you may be asked to submit certain documents for verification of your age and authenticity of other details as part of KYC (Know Your Client) process. Failure to furnish any such information or documents, or in case any information or document submitted by you is found to be false, Your registration along with your account associated with such registration may be suspended or terminated by Us, at Our discretion, without any prior notice. We shall not be liable towards, or be obligated towards, in any manner, for any loss incurred owing to account so suspended or terminated.</p>
            <p>4.2. You acknowledge and agree that completing KYC process as required by the Company shall be a pre-condition to complete registration process on Our Application. Further, you agree that the Company may require you to re-verify your credentials by completing the KYC process on periodical basis.</p>
            <p>4.3. You must register Yourself only once with correct credentials, and shall not make more than one account on the Application, that is to say, You shall access the Services or play the Games only through one account. You are also prohibited from using account of someone else to access the Service or play the Games. In the event, You are found to be accessing the Services or playing the Game(s) through someone else’s account, You may barred by Us, for present and future, from accessing the Services or playing the Game(s), and also, We may, at Our discretion, terminate all the accounts associated with You or such other person without any prior notice. Further, you shall solely be responsible for any damage or loss incurred to any such third-person whose account may be used to access the Services and play the Games(s).</p>
            <p>4.4. By registering yourself and by accessing the Services, You hereby agree to not sharing or distributing your password and/or other account information to any third-person, that is to say, only you shall be authorized to access your account and the Services. We shall not be responsible towards any loss or damage that may occur owing to such sharing of password and other account information. You shall solely be responsible towards all the activities on the Application through your Account. You understand and agree that We shall not incur any liability for information provided by you to anyone which may result in your account on the Application being exposed or misused by any other person or any other harm or loss being suffered by you.</p>
            <p>4.5. You undertake not to impersonate another person or user of Our Application, or attempt to get a password or any other account information not belonging to you for accessing Our Application. We may initiate appropriate legal proceedings against you as well as notifying the relevant regulatory or law enforcement authorities where appropriate, apart from suspending or terminating your privileges for the use of the Application, in the event, we have reasonable grounds to believe that you have violated your undertaking herein in any manner. Any act in violation of this clause by you shall be deemed to be a material violation by us.</p>
            <h4>5. EXCLUDED</h4>
            <p>5.1. The offering of Services by Us shall, at all time, be in complete compliance with the applicable laws. We understand that applicable laws in the Indian states of Assam, Odisha, Telangana, Andhra Pradesh, Nagaland, Sikkim and Arunachal Pradesh (hereinafter “Excluded Territories”) may restrict or prohibit its residents from access to Services and playing the Cash Games, and such stated may impose penalties or punishments on finding any of its residents involved in accessing the Services and playing the Cash Games. Therefore, We, in compliance with such applicable laws, offer Our Cash Games and the Application in all Indian states except Excluded Territories. Accordingly, persons in Excluded Territories are explicitly prohibited form the accessing the Services and playing the Cash Games. We do not accept the registration as registered users on Application from persons in Excluded Territories for allowing to play Cash Games. Accessing the Services from an Excluded Territories by using IP or GPS masking software programs such as fake GPS, GPS Spoofer or others shall be breach of these Terms. In the event, any person in Excluded Territories registers by furnishing false information, We shall terminate the account of such residents forthwith upon knowing the fact of such information being false, and shall forfeit all the winnings or deposits with no obligation or liability whatsoever. You hereby expressly agree that it shall be sole responsibility of you to comply with the applicable state laws, and We shall not be liable in any manner towards breach of any such applicable state laws by you.</p>
            <p>5.2. It may also be noted that the Services are being offered only in India and you shall only access the Services form India, while being in compliance with applicable laws.</p>
            <p>5.3. The Company may, in compliance with the applicable laws, add more Indian states to the list of Excluded Territories, and as a result, you shall be bound by these Terms after such addition.</p>
            <p>5.4 Games of skill are legal, as they are excluded from the ambit of Indian gambling legislations including, the Public Gambling Act of 1867.</p>
            <p>5.5 The game with the mobile app&nbsp; only in skill level knowledge which includes superior knowledge, training, attention, experience and adroitness of the player shall be classified as a game of skill</p>
            <p>5.6 By signing up on this Mobile App, you automatically agree that you are participating in a game of skill</p>
            <h4>6. PAYMENTS AND TAXES</h4>
            <p>6.2. While making any payment as deposits, you shall ensure that all the payments are made in Indian Rupees and the instrument used to make such payment shall belong to you. The payments are made through “Add Cash” section in the Application after you have logged in to your gaming account. The payments shall be subject to the limitation imposed by Us with respect to the DS Rummy cash that can be added to your gaming account. The ability to add cash to your gaming account shall be subject to the monthly “Add Cash” limits, which We can be set by Us with undertakings, indemnity, waiver and verification conditions as We deem appropriate in Our sole discretion.</p>

            <p>6.1. You shall add cash in your gaming account in case you chose to play Cash Games on the Application.
                 The aforementioned cash account shall constitute the follows:<br/>I. Deposits: Deposits are such payments that you deposit in your gaming account and participate in the Cash Game with the object of earning winnings that are more than the deposits so made.<br/>II. Winnings: Winnings are such amount that are won by you as winnings in the Cash Game You have participated. All the winnings are credited by Us in your gaming account.</p>
                 <p>6.3. The Company hereby declare, and User hereby agree, that any Deposit in Your account in accordance with the terms herein shall not constitute deposits in the Company with the meaning of prevailing company laws and other applicable laws.</p>
                 <p>6.4. Payments made through credit card, debit card, prepaid cash card and internet banking are processed by third party payment gateways. Similarly, payments made via other payment modes may require an authorization by the intermediary that processes the payments. We are not responsible for any delays or denials/rejections by such intermediaries or third-party gateways and processing of payments will be solely in terms of their policies and procedures, and We will not be in any way be responsible for the same. If case there are any issues in connection with adding cash to your account, a complaint can be sent to Us in accordance with the complaint procedure provided in the “Complaints and Disputes” section on the Application. You agree that in an event any of your payments is delayed or eventually declined for reasons beyond Our control, We shall not be held liable in any manner whatsoever. Once a payment/transaction is authorized, the money is credited into your account and is available to you to play Cash Games on Our Application.</p>
                 <p>6.5. We have the right to cancel a transaction at any point in time solely at Our discretion, in which case if the payment is successfully made, then the transaction will be reversed and the money credited back into your payment instrument. Players’ funds are held in trust by Us in specified bank accounts or in third party wallet liked with user’s gaming account with Us. We keep all its players’ funds unencumbered and your funds will be remitted to you in due course subject to these Terms and Game Rules applicable to the withdrawal of such funds. Funds held in your user account are held separately from Our corporate funds. Even in the extremely unlikely event of an insolvency proceeding, your claim on the deposits will be given preference over all other claims to the extent permissible by law.</p>
                 <p>6.6. You understand and acknowledge the fact that We are neither a bank nor a financial institution and We shall not be obligated towards payment of any interest on the amount accumulated in your gaming account. You acknowledge that credit to your gaming account by you either for participating in the Cash Games or is a result of cash prizes that you may win as a result of participation in the Cash Game.</p>
                 <p>6.7. Any withdrawals of payments from your gaming account shall be subject to deduction TDS as per Indian income tax laws and other rules and regulations of the Government of India. For the purpose of deduction of TDS, You agree to furnish Your PAN (Permanent Account Number) duly issued to You by the Income Tax Department, Government of India., as the case may be, to deduct TDS as per the rules and regulations of the Government of India.</p>
                 <p>6.8. You acknowledge and agree that We may charge service charges for Cash Games, after prior notice to you in this regard, which may vary depending on the nature of the Cash Game and are subject to change from time to time. Non-Cash Games are offered free on the Application, but may be subject to entry restrictions in some cases. Service charges charged by Us shall be inclusive of all applicable taxes, including GST (Goods and Service Tax).</p>
                 <p>6.9. Without prejudice to the foregoing, you are prohibited to use any credit card, debit card, prepaid cash card, internet banking or any other instrument not belonging to you to make Deposits and add money in your gaming account. You shall solely be responsible towards any loss incurred to the Company or any third-party owing to unauthorized use of any such instrument and accordingly, shall be obligated towards compensating the Company or any such third-party to make good such loss incurred. Additionally, the Company shall be at liberty to initiate and proceed with criminal prosecution against you in accordance with the applicable laws. Any act in violation of this clause by you shall be deemed to be a material violation by Us.</p>
                 <h4>7. WITHDRAWAL POLICY</h4>
                 <p>7.1. The Deposits made by you in your account can be withdrawn by you, by making a specific request in this behalf, subject to completion of KYC. However, Depositing and withdrawing money in your user account without playing any, or playing Cash Games inadequately, shall be inconsistent to Our fair play deposit and withdrawal policy. Such deposits and withdrawals in a consecutive or frequent manner shall be deemed as money laundering actions by you, which is not permitted on or through Our Application. Any violation in this regard, if proved upon Our investigation, shall be liable to penalty and ban from accessing the Services while confiscation of funds in credit in the user account, if any. Additionally, We may initiate appropriate legal action against you, and may, report the relevant authority with respect to your unlawful conduct.</p>
                 <p>7.2. You agree that any bonuses and promotional winnings are subject to withdrawal restrictions and can only be withdrawn after you have made at least one cash Deposit and thereafter, have played the minimum number of Cash Games required to be eligible for the bonus and promotional winnings.</p>
                 <p>7.3. We shall process a withdrawal request via NEFT- Electronic Transfer after KYC and withdrawal verification has been successfully completed. We shall always attempt to process the withdrawal through the mode of transfer selected by you. However, We reserve the right to modify the mode of withdrawal at Our discretion. Additionally, We reserve the right to reverse the amount to the payment/Deposit mode used to add cash in your user account.</p>
                 <p>7.4. We shall endeavor to process all withdrawal requests within 24 hours of your making the request, and the bank may take another 48 hours to deposit the money into your account. There could be delays due to the time required for verification and completing the withdrawal transaction. This may include National and Bank Holidays or disruptions of service. We shall not be liable to pay you any form of compensation for the reason of delays in remitting payments to you from your user account.</p>
                 <p>7.5. Without prejudice to the foregoing, you are permitted to play Games in fair manner in full compliance with the Game Rules, these Terms and other notification as may be made from time to time.</p>
                 <h4>8. INTELLECTUAL PROPERTY</h4>
                 <p>The Company holds absolute ownership of all the tangible or intangible rights with respect to the Application and the Services offered, including all the Games, any promotional material and the technology involved, such right being referred to as the “Intellectual Property Rights”. Notwithstanding, any third-party content offered on the Application as part of Services shall be rights of such third-party. By allowing the access to the Services through Application and to play Games, We are only permitting you to play the Games in accordance with the prescribed Game Rules, and We are in no manner permitting you to use the Intellectual Property Rights including by way of copying, reproducing or transmitting any content, material or media.</p>
                 <h4>9. TECHNICAL FAILURES</h4>
                 <p>9.1. The Company uses information technology-based software to offer the Services and operate the Application. External internet networks and connections are used to connect the Application (operated through your mobile devices and hardware) to Our servers. We do not hold any liability towards any disruptions in such internet networks and connections including any errors, network disconnection or interruptions in communication and shall not be liable towards any loss incurred to you owing to such disruptions. Similarly, We shall not liable towards any loss arising out of any technical failures, server crashes, breakdowns, software defects, disruption or malfunction of Service owing to your fault or failure of your mobile device or hardware.</p>
                 <p>9.2. In the event of any technical failures, server crashes, breakdowns, software defects, disruption or malfunction of service attributed to Us while the Game(s) is in progress, You acknowledge and understand that We have no control over these factors. Further, you hereby expressly acknowledge that We shall not be responsible for any such interruption in Services/ Game(s), and You take full responsibility for any risk of loss owing to Service interruptions for any such reason.</p>
                 <h4>10. REPRESENTATION BY USER</h4>
                 <p>You, by accessing the Services and Application and agreeing to these Term, represent as follows:</p>
                 <p>10.1. That you have capacity to enter into, execute and deliver this agreement, and to exercise your rights and perform your obligations as contemplated hereby, in accordance with the provisions of Indian Contact Act, 1872 and other applicable laws;</p>
                 <p>10.2. That this agreement constitutes the legal, valid and binding obligation on you, and shall be enforceable against you in accordance with these Terms, except as such enforceability may be limited by applicable bankruptcy, insolvency, re-organization, moratorium or similar laws affecting Our rights generally;</p>
                 <p>10.3. That consummation of the Terms contemplated hereby will not (i) conflict with or result in any breach or violation of any of the terms and conditions of, or constitute (or with notice or lapse of time or both constitute) a default under, any instrument, contract or other agreement to which you are a party or by you are bound; (ii) Violate any order, judgment or decree against, or binding upon, you;</p>
                 <p>10.4. That any information and documents reasonably required from you to comply with any applicable anti-money laundering or counter-terrorism financing laws including any applicable laws imposing know your customer or other identification checks or procedures that the Company may require to comply with in respect of this agreement shall be provided by You.</p>
                 <h4>11. WARRANTY</h4>
                 <p>DS Rummy is committed to providing updated, accurate, complete and reliable information by publishing it on the Application. However, We disclaim any and all warranties, expressed and implied, with respect to the Services or Application or any of its content. All the information and content with respect to the Services and Application is provided to you on ‘as is’ basis, and you agree to access the Services and Application at your sole risk. We do not represent the suitability of the information published in documents or any other information that is updated on Our Application. We hereby solemnly disclaim and discard all warranties about any information as well as all implied warranties and conditions of Our merchantability, title and non-infringement, appropriateness to any particular purpose, and accuracy of Our Application and Services, software and Games. We do not give any warranty that the servers used to offer the Services and run the Application will run error-free, uninterrupted, or that they will not get affected by bugs, defects or viruses of any kind. Hence, We reserve the right to determine, at Our sole discretion, whether any issue at Our end is responsible for any error or disruption or malfunction. We may limit your access to Services or Application, if We opine that you are responsible for intentionally causing any malfunction or disruption. We shall not be liable for any assumed or potential wins in any unfinished Games on the Application. You may note that no person is authorized to extend or revise the warranty stated herein.</p>
                 <h4>12. SOFTWARE USAGE</h4>
                 <p>It is strictly forbidden for anyone to use Our Services or Application for any commercial purposes. Our Application is meant only for accessing Services and playing Games solely for personal entertainment. You shall not attempt to manipulate, decode, modify, disassemble, decompile, reverse or re-engineer Our Application or its software in any manner. Please note that Use of Bots or Automatic Play is prohibited. Any attempt to use artificial intelligence that includes, but is not limited to, robots, in connection with our Application is strictly forbidden. You are authorized to access the Services solely through the Application while using the user interface contains therein. Any act in violation of this clause by you shall be deemed to be a material violation by Us.</p>
                 <h4>13. FAIR PLAY POLICY</h4>
                 <p>We take the fair play of the Games very seriously. In order to prevent any form of fraud or unfair play in Our Games, all user actions including deposits and withdrawals, identity verification, and individual game hands are continuously monitored to ensure a safe, legal and fair environment for all Our users. Hence, as part of the fair play policy and to prevent any form of fraud and in-game collusion, all user actions are monitored and recorded as mentioned above. 13.1. Any detection of a breach of Our fair play policy, such as, but not limited to, fraudulent deposits under a stolen identity or collusion in play, shall result in prompt and serious action by Us against the suspected user, in the manner detailed herein below:</p>
                 <h4>13.1.1. ANTI-CHEATING AND ANTI-COLLUSION</h4>
                 <p>13.1.1.1. You undertake that you yourself will play in all Games for which you register/which you join and not use any form of external assistance to play. You shall not add any unauthorized components and not create nor use any cheats, exploits, bots, hacks or any other third-party software designed to modify the Application. You also undertake that you will not use any third-party software that intercepts, mines or in any way collects information from or through the Application or through any of Our Services. Any attempt to employ any such external assistance is strictly prohibited.</p>
                 <p>13.1.1.2. Formation of teams by you and any other user(s) for collusion or any form of cheating in the Games on the Application is strictly prohibited.</p>
                 <p>13.1.1.3. Any act in violation of this clause 13.1.1 by you shall be deemed to be a material violation by Us.</p>
                 <p>13.1.2. Money Laundering&nbsp;You are prohibited from doing any activity on the Application that may be construed as money laundering, including, but not limited to, attempting to withdraw real money added through credit cards or deliberately losing money to a certain player(s). Any act in violation of this clause by you shall be deemed to be a material violation by Us.</p>
                 <p>13.1.3. Anti-Spamming<br/>Sending SPAM emails or any other form of unsolicited communication for obtaining registrations on the Application to benefit from any promotional program or for any other purpose is strictly prohibited.</p>
                 <p>13.1.4. Multiple IDs Your registration on the Application is restricted to a single user account, which will be used by you to avail yourself of the Services provided on the Application. You are prohibited from creating or using multiple user IDs for playing on the Application or using Our Services.</p>
                 <p>13.1.5. You may not create a login name or password, or upload/distribute /transmit/publish/ post content through or on the Application or through any service/facility including any messaging facility provided by the Application which:<br/>I. is libelous, defamatory, obscene, intimidating, invasive of privacy, abusive, illegal, harassing;<br/>II. contains expressions of hatred, expressions hurting religious sentiments, or expressions related to racial discrimination or pornography; III. is in any way objectionable or undesirable (whether or not unlawful); IV. is or can be construed as an incitement to commit a criminal offence; V. violates the rights of any person; VI. is aimed at soliciting donations or other form of help; VII. violates the intellectual property of any person and/or any legally formed entity; VIII. disparage in any manner to Us or any of Our subsidiaries, affiliates, licensors, associates, partners, sponsors, products, services, or Application; IX. promotes a competing service or product; X. violates any applicable laws.</p>
                 <p>&gt;13.1.6. In the event that We determine that the screen name chosen and created by you is indecent, objectionable, offensive or undesirable in any way, We shall notify You about it and you shall promptly provide Us with an alternate username so that We can change your existing username to the new name provided by you. If you fail to provide an alternate username, We reserve the right to either permanently suspend your user account or reactivate Your user account only after you have provided a different, acceptable username.</p>
                 <p>13.1.7. You shall not host, intercept, emulate or redirect proprietary communication protocols, if any, used by the Application, regardless of the method used, including protocol emulation, reverse engineering, nor modify the Application or any files that are part of the Application. Any act in violation of this clause by you shall be deemed to be a material violation by Us.</p>
                 <p>13.1.8. You shall not frame the Application. You may not post or upload editorial comments, commercial material or any information on the Application, alter or modify content on the Application, or remove, obliterate or obstruct any proprietary notices or labels.</p>
                 <p>13.1.9. You shall not use the Services on the Application for any commercial purposes including, but not limited to, use in a cyber café as a computer gaming center, network play over the Internet or through gaming networks or a connection to an unauthorized server that copies the gaming experience on the Application. Any act in violation of this clause by you shall be deemed to be a material violation by Us.</p>
                 <p>13.1.10. You shall not upload, distribute or publish through the Application any content that may contain viruses or computer contaminants (as defined in the Information Technology Act, 2000 or such other laws in force in India at the relevant time) which may interrupt, destroy, limit the functionality of or disrupt any software, hardware or other equipment belonging to Us or that aids in providing the services offered by Us. You shall not disseminate or upload viruses, programs, or software to the Application whether it is harmful to the Application or not. Any act in violation of this clause by you shall be deemed to be a material violation by Us.</p>
                 <p>13.1.11. You shall not purchase, sell, trade, rent, lease, license, grant a security interest in, or transfer your user account, content, currency, points, standings, rankings, ratings, or any other attributes appearing in, originating from or associated with the Application.</p>
                 <p>13.1.12. Any form of fraudulent activity including attempting to use or using any other person’s credit card(s), debit cards, net-banking usernames, passwords, authorization codes, prepaid real money cards, and mobile phones for depositing money into your account is strictly prohibited. Any act in violation of this clause by you shall be deemed to be a material violation by Us.</p>
                 <p>13.1.13. Accessing or attempting to access Our Services through someone else’s user account is strictly prohibited.</p>
                 <p>13.1.14. Winnings, bonuses, bonus codes and prizes are unique to the player and are non transferable. In the event you attempt to transfer any winnings, bonuses or prizes, or share special bonus codes, the benefits of the same shall be forfeited.</p>
                 <p>13.1.15. The Company and its officers, directors, employees, consultants and agents and relatives of such persons (“Associated Persons”) have no access to the site’s systems, databases, and server-side operations and have no such information that allows any manipulation of the cards that are dealt and have no special access other than what is available to any user of the Application. They are also prohibited from playing, directly or indirectly, any real money games on the Application, other than any specific term of the engagement with Us from time to time.</p>
                 <p>13.1.16. You shall not post any material or comment on any media available for public access that, in Our sole discretion, is defamatory or detrimental to Our business interests, notwithstanding the fact that such media is not owned or controlled by Us. In addition to any other action that We may take pursuant to the provision hereof, We reserve the right to remove any and all material or comments posted by You and restrict Your access to any media available for public access that is either controlled or moderated by Us, when in Our sole opinion, any such material or comments posted by You is defamatory or detrimental to Our business interests. 13.2. Breach and Consequences If We have evidence, through Our investigations or reasonable belief, of a breach of these Terms by you and/or that your continued access to Our Application is detrimental to the interests of Company or any other users or the general public, then We reserve the right to take any or all of the actions stated herein below at Our sole discretion:</p>
                 <p>13.2.1. Permanently suspend your user account on Our Application;</p>
                 <p>13.2.2. Forfeit the amount of cash left in your account;</p>
                 <p>13.2.3. Demand and order damages for breach of these Terms and take required civil actions to recover damages;</p>
                 <p>13.2.4. Initiate steps of prosecution for damages and violation that is equivalent to offences in law;</p>
                 <p>13.2.5. Put restrictions on the access of Our Games by users who are suspected in cases of cheating or colluding;</p>
                 <p>13.2.6. Bar you from playing or registering on Application in the future;</p>
                 <p>13.3. The action taken by Us will be solely due to your breach of Our Terms; the action shall be final and decisive that will be binding on you. Any action taken by Us will be without any prejudice to Our other rights and remedies that are mentioned and available in law or equity. 13.4. Without prejudice to the foregoing, you agree that you shall be obligated to compensate the Company for any loss or damage incurred to the Company as a result of breach of any of these Terms. Additionally, in case of any material violation, as referred to in these Terms, you shall be liable to compensate the Company with an amount of INR [100,000,00] or the amount of actual loss or damages, as may be quantified by Us, incurred to the Company, whichever is higher.</p>
                 <h4>14. CONTENT AND CONTRIBUTION</h4>
                 <p>14.1. All the online content, materials and information on Our Application are collectively referred to as ‘Content’. It may include a wide range of online items such as images, designs, multimedia clips, graphics, logos, information, text content, games, software, animation, marks, hyperlinks, and pictures. The Content, whether or not belonging to Us, are thus protected and secured by applicable intellectual property rights. Additionally, We shall be free to record and use, in any manner whatsoever, all chat content, images, emails, and recommendations sent by any member or user on Our Application, as they appear on the Application and form part of the Content.</p>
                 <p>14.2. It is informed that Our Application may contain information about third-parties and may comprise of hyperlinks within them. You shall use or access such third-party content or hyperlink at your sole risk. We shall not be responsible in any way for the implied warranty about the quality, integrity or accuracy of such third-party content. You expressly acknowledge that We shall not be responsible for any content belonging to any third-party, such third-party content appearing on Our Application shall not be deemed as endorsement towards such thirdparty content by Us.</p>
                 <p>14.3. You agree to not to post any content on the Application that does not belong to you, or with regards to which you do not have any rights or permission to post. You agree that you shall be solely be responsible towards any unlawful posting and shall be obligated to compensate Us for any loss or damage that We may have to bear on account of you posting any content unlawfully.</p>
                 <h4>15. REFUND POLICY</h4>
                 <p>15.1. The Company shall not be responsible for any loss resulting from the cancellation of any Games due to Service outages caused by the failures of Our service providers, computer viruses, natural disasters or any other reason that is beyond Our control. You acknowledge that you will not be entitled to any refund due to cancellation of games caused by factors that are beyond Our reasonable control. 15.2. In cases of accidental or erroneous cash credits to any party, user or company, and where Our reasonable independent investigation is not required in Our opinion, We may independently, or upon Your request, initiate the refund process within 2 (two) working days of such accidental or erroneous transaction. In cases where in Our opinion Our reasonable independent investigation is required, We shall process such refund that are proved to genuine upon Our such reasonable independent investigation that may take up to 2 (two) weeks from the date of bringing such accidental or erroneous credit to the knowledge of the Company. Any such refund shall be made to the financial instrument/platform that was used to make the corresponding payments.</p>
                 <h4>16. AMENDMENT</h4>
                 <p>In the event any provision or part-provision of these Terms is or becomes invalid, illegal or unenforceable, it shall be deemed modified to the DS Rummy extent necessary to make it valid, legal and enforceable. In case such modification is not possible, the relevant provision or part provision shall be deemed deleted. Any modification to or deletion of a provision or part provision shall not affect the validity and enforceability of the rest of these Terms.</p>
                 <h4>17. LIMITATION OF LIABILITY</h4>
                 <p>You understand and agree that the Company shall be liable towards any direct or indirect damages, resulting from use of or inability to use the Service, even if We have been advised or become aware of the possibility of such damages or loss or that such loss was foreseeable. Such damages may include injury, claim, loss of data, loss of income, loss of profit or loss of opportunity, loss of or damage to property, damages for monetary loss, goodwill, use, data or other intangible loss (even if the Company has been advised of the possibility of such damages). You agree to indemnify Us against any claims in respect of any such matters.</p>
                 <h4>18. ASSIGNMENT</h4>
                 <p>Your rights and obligations under this agreement is personal to you and shall not be assigned to any other party or person. The Company may, at its sole discretion, assign, transfer or novate this agreement or any of its rights and/or obligations under this agreement to any person, including their affiliate.</p>
                 <h4>19. RELATIONSHIP BETWEEN PARTIES</h4>
                 <p>of the provisions of this agreement shall be deemed to constitute a partnership between the Company and user, and no party shall have any authority to bind the other parties otherwise than under this agreement or shall be deemed to be the agent of other in any way.</p>
                 <h4>20. GOVERNING LAW AND DISPUTE RESOLUTION</h4>
                 <p>All disputes and differences arising from this agreement shall be referred to the Sole Arbitrator appointed by Company and User on mutual agreement, and such reference shall all time be governed by the provisions of the Arbitration and Conciliations Act, 1996.</p>
                 <h4>21. CONTACT</h4>
                 <p>For any queries or suggestions, you may freely contact us at DS Rummy dsindiancorporation99@gmail.com In the event you suspect any unauthorized use of your login credentials or any other information, you may inform us immediately requesting suitable actions.</p>
                 <h4>22. COMPANY DETAILS</h4>
                 <p>Company Name: DS INDIA CORPORATION.</p>
                 <p>Email: dsindiancorporation99@gmail.com</p>
                 <p>Number: +91 974 181 0471</p>
                 <p>Address: GROUND FLOOR, 1-S-14-932/3, DHANYA COMPLEX,ASHOK NAGAR, URVA MARIGUDI ROAD, Mangaluru,Dakshina Kannada, Karnataka, 575006</p>


        </div>
    </div>
  )
}

export default TermsSection