import React from 'react'
import { GoArrowRight } from "react-icons/go";



const AboutHeader = ({title,data}) => {
  return (
    <div className='about-us-section'>
    <div className="bg-overlay-about"></div>
              <div className="container text-light d-flex justify-content-center position-relative z-1 flex-column-reverse flex-lg-row">
             
              <div>
              <h1 className='text-center'>{title}</h1>
              <div className='d-flex justify-content-center'>
                <div>HOME <GoArrowRight/> {data}</div>

              </div>
              {/* <ul className='mx-auto common-section-breadcrumb' >
              <li>
                <span>Home</span>
                </li>
                <li>
                    <span><GoArrowRight/></span>
                   
                </li>
                <li> <span>{data}</span> </li>
               </ul> */}
              
                </div>     
                   
               
            </div>
            
             
 </div>

  )
}

export default AboutHeader