import Footer from "./components/Footer";
import Header from "./components/Header";
import {BrowserRouter, Route,Routes} from 'react-router-dom';
import Home from "./components/sections/Home";
import About from "./components/sections/about/About";
import Contact from "./components/sections/contact/Contact";
import PrivacySection from "./components/sections/privacy/PrivacySection";
import TermsSection from "./components/sections/terms/TermsSection";
import CancellationSection from "./components/sections/cancellation/CancellationSection";
import WithDrawSection from "./components/sections/withdraw/WithDrawSection";

function App() {
  return (
    <BrowserRouter>
    <div className="rummy-wrapper">
     <Header/>
    <Routes>
      <Route path = "/" element= {<Home/>}></Route>
      <Route path = "/about-us" element= {<About/>}></Route>
      <Route path = "/contact" element= {<Contact/>}></Route>
      <Route path="/privacy-policy" Component = {PrivacySection}></Route>
      <Route path="/terms-conditions" Component ={TermsSection}></Route>
      <Route path="/cancellation-refund-policy" Component={CancellationSection}></Route>
      <Route path="/withdrawal-policy" Component={WithDrawSection}></Route>
    </Routes>
    <Footer/>
    </div>
    </BrowserRouter>
  );
}

export default App;
