import AboutPokerImg from "../../../assets/images/istockphoto-628650056-612x612-1.jpg";


const AboutDescription = () => {
  return (
    <div className="about-section">
    <div className="container d-flex flex-column flex-lg-row">
        <div className="w-50">
            <img src={AboutPokerImg}  loading="lazy"  alt="poker-img" className="img-fluid" />
        </div>
        <div className="w-50">
            <h2 className="heading-title">DS INDIA CORPORATION</h2>
            <p>DS Rummy, is a real-time online rummy card game which fully owned by SKYYEET SOFTWARE TECHNOLOGY PRIVATE LIMITED.</p>
            <p>We are a passionate team of designers and developers with years experience in the card game industry. Our aim is to provide you an awesome online experience on this sophisticated handmade product!</p>
           
        </div>
    </div>
</div>
  )
}

export default AboutDescription;