import { BsArrowRight } from "react-icons/bs";

const DownloadBtn = ({
  btnClass,
  text = "Download App",
  iconEnable = true,
}) => {
  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = "/apks/DS Rummy.apk";
  
    link.download = 'DS Rummy.apk';
 
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <button className={`btn download-app  ${btnClass}`} onClick={handleDownload}>
      {text} {iconEnable && <BsArrowRight className="ms-2" />}
    </button>
  );
};
export default DownloadBtn;
