/* eslint-disable jsx-a11y/anchor-is-valid */
import { NavLink } from "react-router-dom";
import { FaFacebook } from "react-icons/fa";

const Header = () => {
  return (
    <header className="header">
      <div className="header--top-bar">
        <div className="container mx-a d-flex justify-content-between py-2">
          <div className="d-flex text-light">
            <div className="p-1 pe-4 border-1 border-light border-end">
            dsindiancorporation99@gmail.com
            </div>
            <div className="p-1 ps-4">+91 974 181 0471</div>
          </div>
          <div className="p-1 header--top-bar--fb-icon">
            <FaFacebook />
          </div>
        </div>
      </div>
      <div className="header--menu py-2">
        <nav className="navbar">
          <div className="container">
            <a className="navbar-brand text-light" href="#">
              <span>DS</span> Rummy
            </a>
            <ul className="navbar-nav  d-none d-lg-flex flex-row ">
              <li className="nav-item px-3">
                <NavLink className="nav-link"  to="/">
                  Home
                </NavLink>
              </li>
              <li className="nav-item px-3">
                <NavLink className="nav-link" to="/about-us">
                  About Us
                </NavLink>
              </li>
              <li className="nav-item px-3">
                <NavLink className="nav-link" to="/contact">
                  Contact Us
                </NavLink>
              </li>
            </ul>
            <div className="nav-btn d-none d-lg-flex">
              <button onClick={handleDownload} className="btn btn-primary px-4 py-2">
                Download App
              </button>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};
export default Header;

const handleDownload = () => {
  const link = document.createElement('a');
  link.href = "/apks/DS Rummy.apk";

  link.download = 'DS Rummy.apk';

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
