import CommonHeader from '../CommonHeader.js';

const PrivacySection = () => {
  return (
    <div >
        <CommonHeader title={"Privacy Policy"} data={"PRIVACY POLICY"}/>
        <div className='container d-flex flex-column privacy-section'>
            <p>This Privacy Policy forms part of our Website’s Terms of Service and by accepting the Terms of Service and Privacy Policy on our registration page, you consent to share your sensitive personal data or personal information and are aware of the purpose of sharing such information. Please read this Privacy Policy carefully before using DS Rummy services. Please do not register if you do not wish to share with  https://dsrummy.in/ (hereinafter referred to as “DS Rummy” or “we” or “us” or “our”) the mandatory personal information requested at the time of registration. Registration procedure cannot be completed until information sought in the non-optional fields is provided. Certain additional personal information may be requested by DS Rummy  for permitting you access to any Cash Games hosted by DS Rummy. This Privacy policy applies to our services available under the domain and sub-domains of the Website (https://dsrummy.in/).</p>
            <h4>1. WHAT IS THE OBJECTIVE OF THIS PRIVACY POLICY?</h4>
            <p>DS Rummy respects your privacy and assures you that any information provided by you to Rummy time is protected and shall be dealt with according to this Privacy Policy and the applicable laws. To avail yourself of the services offered on https://dsrummy.in/ (hereinafter referred to as the “Website”), you may have to provide certain information to us. This Policy delineates the procedure followed by DS Rummy to gather, use, store, disclose and manage users’ personal data. DS Rummy collects personal data only for the purpose of verifying user accounts, maintaining the accounts of users, completing transactions initiated by users and for analyzing user behavior and requirements. Along with providing users with the most enjoyable online rummy gaming experience, we also build a relationship of trust with our users. Our commitment to protecting user data is based on the principles of transparency, fairness, accuracy, data minimization, integrity and confidentiality.</p>
           <h4>2. WHAT IS INCLUDED IN PERSONAL DATA?</h4>
           <p>Users’ personal data includes the following types of personal data or information:</p>
           <h4>1. Sensitive Personal Data</h4>
           <ul>
            <li>
            Account password
            </li>
            <li>Financial information such as Bank Account, Credit Card, Debit Card or other payment instrument details</li>
           </ul>
           <h4>2. Other Personal Information</h4>
           <ul>
           <ul className='d-flex flex-row p-0 m-0'>
            <li>Name</li><li>Date of birth</li>
           </ul>
           <li>Telephone number</li>
           <li>Postal/Contact address</li>
           <li>PAN (as applicable)</li>
           <li>The IP address of your computer, browser type and language.</li>
           <li>The date and the time of your accessing the site.</li>
           <li>The address of the website that you may have used to link to our Website</li>
           <li>Your photograph for the purpose of testimonials and other promotions.</li>
           <li>Any other information that is defined as “sensitive personal data or information” in law.</li>
           </ul>
           <h4>3. Users’ Consent</h4>
           <p>Consent to the use of Sensitive Personal Data and Other Personal Information</p>
           <p>All users of DS Rummy consent to the use of their Sensitive Personal Data and Other Personal Information by DS Rummy for the purposes stated in this policy. We restrict access to personal user information to our employees, contractors and agents and only allow access to those who need to know such information in order to process it on our behalf.</p>
           <p>Users are entitled to withdraw consent to the use of their Sensitive Personal Data or Other Personal</p>
           <p>Information by emailing a specific request to dsindiancorporation99@gmail.com Withdrawal of consent to use all or any part of Sensitive Personal Data and Other Personal Information may result in immediate withdrawal of any right provided to the user to use the services provided by DS Rummy Withdrawal of consent by users shall not restrict the right of DS Rummy to use Other Personal Information for the analysis of user data, subject to the condition that the Other Personal Information used in such analysis is not personally identifiable to any individual user.</p>
           <p>Users’ providing photographs to DS Rummy for marketing purposes gives DS Rummy irrevocable rights to use them and it is solely DS Rummy  discretion to delete or not use them any further.</p>
           <p>DS Rummy may also use software applications for Website traffic analysis and to gather statistics, used for advertising and for determining the efficacy and popularity of  DS Rummy and other such purposes.</p>
           <h5>Consent to the use of cookies</h5>
           <p>A cookie is a small text file that uniquely identifies your browser.</p>
           <p>You understand that when you visit the Website, cookies may be left in your computer. The cookies assigned by the servers of DS Rummy may be used to personalize your experience on the Website.</p>
            <p>Additionally, cookies may also be used for authentication, game management, data analysis and security purposes.</p>
            <p>Cookies may also be assigned by the advertisers of DS Rummy when you click on any of the advertisements, which may be displayed on any website, in which case such cookies are controlled by these advertisers and not DS Rummy.</p>
            <h5>Consent to email communication</h5>
            <p>When you register your email address with DS Rummy , you agree to receive email communication from DS Rummy , entities specifically authorized by DS Rummy, and other users. You also agree and acknowledge that when you use our referral program for referring someone, DS Rummy may send emails to the person referred by you on your behalf and the email headers will carry your email address as the address from which such emails are sent.</p>
            <p>DS Rummy may also access and store such information relating to your contacts to send invitations and other promotions to them periodically.</p>
            <p><strong>Consent to telephonic communication</strong></p>
            <p>Any telephonic communication between you and our Customer Support staff may be recorded for the purposes of employee training and quality management, as well as the speedy resolution of your queries.</p>
            <h4>4. DATA SECURITY</h4>
            <p>We take appropriate security measures to protect user data from unauthorized access and its unauthorized alteration, disclosure or destruction. These measures include internal reviews of our data collection, storage and processing practices and security measures, including appropriate encryption and physical security measures to guard against unauthorized access to systems where we store users’ personal data. DS Rummy has a comprehensive information security program and information security policies that contain managerial, technical, operational and physical security control measures adopted by Rummy Winner for the protection of Sensitive Personal Data and Other Personal Information. Information gathered by DS Rummy is stored securely using several information security applications including firewalls. However, security is always relative and DS Rummy cannot guarantee that its security measures are absolute and cannot be breached. Data transmitted over the Internet is inherently exposed to security risks or threats. For instance, the security of information transmitted via chat or email might be compromised and such information may be used by others. Therefore, DS Rummy cannot guarantee any security for such information in the course of transmission through the internet infrastructure or any unsolicited disclosures made by any users using the services of the Website. When you register with DS Rummy, your account is protected by means of login information, including a username and a password, which is known only to you. Therefore, you should not provide your personal information to anyone whosoever: breach hereof constitutes violation of this Policy and may result in closure of your account in certain cases. If you become aware of or reasonably suspect any breach of security, including compromise of your login information, it is your responsibility to immediately notify DS Rummy.</p>
            <p>The Website may contain links to other websites. Such websites are governed by their own privacy policies, and DS Rummy  does not exercise any control over them. It is your responsibility to read and understand the privacy policy of such websites when you visit them following links provided on the Website. You are advised to exercise caution in sharing any personal information with any third party that advertises on the Website: DS Rummy shall not be responsible for the information provided by you on or to third party websites.</p>
            <p>DS Rummy has a policy of not sharing any personally identifiable information with anyone other than the entities specifically authorized by DS Rummy, which may include advertisers and sponsors of DS Rummy. However, DS Rummy may use information like your name, photo, login ID and the state from where you are participating when announcing the results of any contests hosted on the Website. Such contests are further governed by terms and conditions, which shall be available on the Website as and when such contests are hosted on the Website. DS Rummy conducts periodic analysis and survey of the traffic to https://dsrummy.in/ for market research and advertising purposes. DS Rummy  reserves the right to share your registration information with DS Rummy -appointed market research and advertising companies or firms from time to time for the said purposes. DS Rummy may also use cumulative non-personal information for auditing and analysis purposes with the aim of improving its services.</p>
           <ol start={5}>
            <li><strong>Privacy measures for minors</strong></li>
           </ol>
           <p>Our Website and contests are meant only for persons aged 18 or above. If you are under 18 years of age, you are not permitted to use our Website. If you are a parent and/or guardian and believe that we may have inadvertently collected personal information from your child, you can notify us immediately by sending an email to dsindiancorporation99@gmail.com</p>
           <p>DS Rummy may share Sensitive Personal Data and Other Personal Information if sharing of such information is necessary</p>
           <ul>
            <li>to comply with legal processes or governmental requests;</li>
            <li>to enforce its Terms of Service and this Privacy Policy;</li>
            <li>for prevention of fraud;</li>
            <li>for issues involving information security, or</li>
            <li>to protect:</li>
            <li className='ul-section'>1. your rights,</li>
            <li className='ul-section'>2. rights of DS Rummy, or</li>
            <li className='ul-section'>3. rights of the general public.</li>
           
           </ul>
           <h4>7. LIMITATION OF LIABILITY</h4>
           <p>DS Rummy confirms that this Privacy Policy is only a description of its operation regarding user information. This Privacy Policy is not intended to create, and does not create, any legal rights in your favor or in favor of any other person. DS Rummy reserves the right to change this Privacy Policy at any time without giving you prior notice. The liability of DS Rummy shall be limited to the removal of Sensitive Personal 
            Data from the system of the Website and the removal of personally identifiable elements of the Other Personal Information. Notwithstanding anything to the contrary contained in this Policy and elsewhere, the aggregate liability of DS INDIA CORPORATION for all claims for damages pursuant to the provisions of services on the Website, including claims in respect to the violation of this Policy, shall be limited to the aggregate DS Rummy amount of liability as provided in the Terms of Service.
             Your continued use of the Website constitutes your agreement to this Privacy Policy and any amendments thereof.</p>
             <ol start={8}>
                <li>All complaints related to personal data must be sent to the Grievance Officer at : dsindiancorporation99@gmail.com</li>
             </ol>
            </div>
    </div>
  )
}

export default PrivacySection