

const AboutSubSection = () => {
  return (
    <div style={{paddingTop: "10%"}}>
        <div className=" d-flex flex-column  justify-content-center align-items-center">
            <p style={{color: "#FF6635",fontSize:"16px",fontWeight: "600",letterSpacing:"5px"}}>WHAT WE PROVIDE</p>
            <p style={{fontSize:"45px",fontWeight:"600",lineHeight:"1.3em",letterSpacing:"0.5px",paddingBottom:"40px"}}>Every great business is built on friendship</p>
        </div>
    </div>
  )
}

export default AboutSubSection