import { FaMapMarkerAlt, FaPhoneAlt, FaRegBuilding } from "react-icons/fa";
import { MdEmail } from "react-icons/md";

const DetailsSection = () => {
  return (
    <div className="contact-container">
        <h1 style={{color:"black",fontSize:"45px",letterSpacing:"0.3px",lineHeight:"1.3em",fontWeight:"600",paddingTop:"40px"}}>WE'RE HERE TO HELP YOU</h1>
        <div style={{color:"#7A7A7A",fontSize:"14px",paddingBottom:"10px"}}>We have world class, flexible support via Call, email and hone. I guarantee that you’ll be able to have any issue resolved within 24 hours.</div>
        <ul className="list-unstyled contact-details">
                    <li>
                        <a style={{fontSize:"14px",paddingBottom:"5px"}}><FaRegBuilding className="me-3" />DS INDIA CORPORATION</a>
                    </li>
                    <li>
                        <a style={{fontSize:"14px",paddingBottom:"5px"}}><FaMapMarkerAlt className="me-3" />FLAT NO.5, SECOND CROSS STREET, NAGARTHNAMMA BUILDING,BTM I STAGE, Madivala, Benguluru urban, Karnataka, 560068</a>
                    </li>
                    <li>
                        <a style={{fontSize:"14px",paddingBottom:"5px"}} ><MdEmail className="me-3" />dsindiancorporation99@gmail.com</a>
                    </li>
                    <li>
                        <a style={{fontSize:"14px",paddingBottom:"5px"}}><FaPhoneAlt className="me-3" />+91 974 181 04711</a>
                    </li>
                </ul>
               <div className="container position-relative"> 
                  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3889.246097381328!2d74.82515697792851!3d12.891890854969615!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba35a60538cff13%3A0xf241ce38df65171!2sUrwa%20Marigudi%20Rd%2C%20Ashok%20Nagar%2C%20Mangaluru%2C%20Karnataka%20575006!5e0!3m2!1sen!2sin!4v1717228245924!5m2!1sen!2sin"
                 style={{border: "none",width:"100%",height:"100%",minHeight:"500px"}} 
                 allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    
            </div>
    </div>
  )
}

export default DetailsSection